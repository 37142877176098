import { Grid, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonAppBar from "../../Common/Appbar/Index";
import { useHistory, useLocation } from "react-router-dom";
import LeftSide from "../LeftSide/Index";
import RightSide from "../RightSide/Index";
import { useFormik } from "formik";
import { loginInitialValues, loginValidationSchema, onboardInitial } from "../data";
import { useSelector } from "react-redux";
import { OnboardInintState } from "../../../store/reducers/onboard";
import { useDispatch } from "react-redux";
import { SigninSteps } from "../data";
import { userSignIn } from "../../../utils/signInHelper";
import { RootInitStateInterface } from "../../../store/reducers/root";
import { OnboardingInintState } from "../../../store/reducers/onboarding";
import "../Index.css";
import { theme } from "../../../NewTheme";
import { Loader } from "../../../NewLoadingScreen";
import { Crisp } from "crisp-sdk-web";
import { SHOPIFY_V2_SEARCH_PARAM } from "../../../utils/const";
import crypto from 'crypto';

function decryptMessage(encryptedText: string, passphrase: string): string {
  const decipher = crypto.createDecipher('aes-256-cbc', passphrase);
  let decryptedMessage = decipher.update(encryptedText, 'hex', 'utf8');
  decryptedMessage += decipher.final('utf8');
  return decryptedMessage.toString();
}


const getNewCreds = (query: string = '') => {
  const [, params] = query.split("?");


  if (!params) {
    return null;
  }

  const paramsArray = params.split("&");
  const newCreds = paramsArray.find((param) => param.includes(SHOPIFY_V2_SEARCH_PARAM));


  if (newCreds) {
    const [, encrypted] = newCreds.split("=");

    const value = decryptMessage(encrypted, '30eb3055daf50e95010300e28b48d1e7')
    const [idToken, refreshToken, accessToken] = value.split(":");

    const shop = paramsArray.find((param) => param.includes("aaa")).split("=")[1];
    const user = paramsArray.find((param) => param.includes("ddd")).split("=")[1];

    return { idToken, refreshToken, accessToken, user, shop };
  }

  return null;
}


/**
 * @Description This is the main component for the login page, it is the newlly refactored version of the login page
 * @param {void}
 * @return {JSX}
 */

function Index() {
  const state: OnboardInintState = useSelector((state: any) => state.onboard);
  const { stepCount, loader }: OnboardingInintState = useSelector((state: any) => state.onboarding);
  const { shopName, token }: RootInitStateInterface = useSelector((state: any) => state.root);
  const [step, setStep] = useState<number>(1);
  const [init, setInit] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const CurrentStep = SigninSteps[step];
  const { search } = useLocation();
  const newCredsCheck = getNewCreds(search);


  const formik = useFormik({
    initialValues: loginInitialValues,
    validateOnChange: true,
    validationSchema: loginValidationSchema,
    onSubmit: async function () {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });
      await handleSignin(formik);
      dispatch({ type: "ONBOARDING_LOADER", payload: false });
    },
  });


  useEffect(() => {
    if (stepCount > step) {
      setStep(stepCount);
    }
  }, [stepCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (Crisp.isCrispInjected()) {
    $crisp.push(["do", "chat:hide"]);
  }

  useEffect(() => {
      const { email, password } = state;

      formik.setValues({ email, password });
    }
  , []); // eslint-disable-line react-hooks/exhaustive-deps

  const loginFormik = useFormik({
    initialValues: onboardInitial(state.provider, state.providerName, state.paymentPlan),
    validateOnChange: true,
    onSubmit: function (values) {},
  });

  useEffect(() => {
    const newCreds = getNewCreds(search);
    const { email, password, provider, providerName, paymentPlan } = state;

    loginFormik.setValues({
      provider,
      providerName,
      paymentPlan,
    });
    setStep(stepCount);
    setTimeout(() => {
      setInit(false);
    }, 500);

    if (newCreds !== null) {
      dispatch({ type: "ONBOARDING_LOADER", payload: true });

      handleSignin({ newCreds,  isNewUser: true  });

    } else if (email && password) {
      formik.setValues({ email, password });
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!init) {
      dispatch({ type: "CHANGE_FORM", payload: { ...loginFormik.values } });
    }
  }, [loginFormik.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const form = step === 0 ? formik : loginFormik;

  async function handleSignin(formik: any) {

    if (formik?.isNewUser) {
      const { idToken, refreshToken, accessToken, user, shop } = formik.newCreds;
      await userSignIn({
        emailAddress: user,
        password: accessToken,
        setLoadings: () => {},
        token,
        shopName,
        history: { push: history.push, location: { search: history.location.search } },
        dispatch,
        setStep,
        isNewUser: true,
        shop,
        v2Creds: { idToken, refreshToken, accessToken, }
      })
    } else {
      const { email, password } = formik.values;

      await userSignIn({
        emailAddress: email,
        password,
        setLoadings: () => {},
        token,
        shopName,
        history: { push: history.push, location: { search: history.location.search } },
        dispatch,
        setStep,
        isNewUser: false,
        shop: ''
      });

      formik.resetForm();
    }

  }

  if (newCredsCheck !== null) {


    return <></>
  }


  return (
    <ThemeProvider theme={theme}>
      {" "}
      <div className="bg-white" style={{ minHeight: "100vh", position: "relative" }}>
        {loader && <Loader content={loginLoaderContent[step]} />}
        <ButtonAppBar />
        <div
          style={{
            width: loader ? "100%" : "90%",
            margin: "auto",
            minHeight: "100vh",
          }}
        >
          <Grid container className="py-20" columnSpacing={3}>
            <Grid item xs={12} sm={5} md={6} lg={8}>
              <LeftSide step={step} />
            </Grid>
            <Grid item xs={12} sm={7} md={6} lg={4}>
              <RightSide>
                <CurrentStep step={step} formik={form} />
              </RightSide>
            </Grid>
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Index;

const loginLoaderContent = {
  0: "Just a moment please! We're logging in now",
  1: "Just a moment please! We're connecting to the shopify.",
};

export enum PlansPrice {
  LITE = "$0",
  BASIC = "$0",
  PREMIUM = "$349",
  STARTER = "$99",
  PLUS = "$199",
  ULTRA = "$349",
  STARTER_ANNUALLY = "$1099",
  PLUS_ANNUALLY = "$2199",
  ULTRA_ANNUALLY = "$3999",
  BASIC_ANNUALLY = "$0",
  PREMIUM_ANNUALLY = "$3999",
}

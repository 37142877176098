import { Auth, Amplify } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import type {CognitoUser} from "amazon-cognito-identity-js";

import { LocalStorage, loadAndGetLocalStorage, clearLocalStorage } from "./loadAndGetLocalStorage";
import { WarningToaster, ErrorToaster } from "../toast";

interface User {
  companyName: string;
  email: string;
  verfied: boolean;
  firstName:string;
  lastName:string;
}

/**
 * Configures the amplify authentication with configurations.
 * Call this when application starts.
 * @returns True for success
 */
export const configureAuth = async (isNew: boolean = false) => {
  const localStorage: LocalStorage = await loadAndGetLocalStorage();
  return new Promise((resolve) => {
    let config = {
      region: localStorage.awsConfig?.awsRegion,
      userPoolId: localStorage.awsConfig?.userPoolId,
      userPoolWebClientId: localStorage.awsConfig?.userPoolWebClientId,
      authenticationFlowType: localStorage.awsConfig?.authenticationFlowType || 'USER_SRP_AUTH',
      mandatorySignIn: localStorage.awsConfig?.mandatorySignIn || false, // Change this to true if you are using Federated Identities
    }

    if (isNew) {
      config = {
        region: 'us-east-2',
        userPoolId: 'us-east-2_YqvgT30yb',
        userPoolWebClientId: '2t3f68e3g7q9ioah6bdlt2ajg0',
        authenticationFlowType: 'USER_SRP_AUTH',
        mandatorySignIn: false, // Change this to true if you are using Federated Identities
      }
    }

    if (config.region) {
      Amplify.configure({
        Auth: config,
      });
      resolve(true);
    }
  });
};

/**
 * Signs up a new user, does not perform any check or valdiates the input
 * @returns CognitoUser
 */
export async function signUp(companyName: string, email: string, password: string,firstName:string,lastName:string): Promise<User> {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: companyName,
          "custom:firstName":firstName,
          "custom:lastName":lastName
        },
      });

      resolve({
        companyName: companyName,
        email: email,
        verfied: user.userConfirmed,
        firstName:firstName,
        lastName:lastName,
      });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Signs out the user
 * @returns Promise with "success" or err
 */
export const signOutUser = () => {
  return new Promise((resolve) => {
    Auth.signOut()
      .then(() => {
        $crisp.push(["do", "session:reset"]);
        clearLocalStorage();
        localStorage.clear()
        resolve("success");

      })
      .catch((err) => console.log("here"));
  });
};

/**
 * Signs in the cognito user pool
 */
export const signIn = async (email: string, password: string, props: any): Promise<User> => {
  return new Promise<User>(async (resolve, reject) => {
    try {
        let user;
        if (props?.isNewUser) {
          try {
            await configureAuth(props?.isNewUser);

            user = await authorizeByToken(props.v2Creds, {
              userPoolId: 'us-east-2_YqvgT30yb',
              clientId: '2t3f68e3g7q9ioah6bdlt2ajg0',
            });

          } catch (Error) {
            console.error('Auth Error', Error)
            reject(Error)
          }

      } else {
        user = await Auth.signIn(email.trim(), password.trim());
      }

      if (user) {
        resolve({
          companyName: user.attributes.name,
          email: user.attributes.email,
          verfied: user.attributes.email_verified,
          firstName:user.attributes["custom:firstName"],
          lastName: user.attributes["custom:lastName"],
        });
      } else {
        reject("unable to sign in");
      }

    } catch (error: any) {
      props.setLoadings(false);
      if (error["message"] === "User is not confirmed.") {
        await Auth.resendSignUp(email);
        WarningToaster("Please verify using link we have sent to the email");
      }

      if (error["message"] === "Incorrect username or password.") {
        ErrorToaster(error["message"]);
      }
      console.log("error signing in", error);
      reject(null);
    }
  });
};

/**
 * Initiates a forget password request
 * @param email email owned by the user
 * @returns resolves if successfull otherwise expect rejection
 */
export const forgotPassword = async (email: string) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await Auth.forgotPassword(email);
      resolve();
    } catch (error) {
      console.log("error forgetting password in", error);
      reject(error);
    }
  });
};

/**
 * Provided with a verification code, user can reset the password
 */
export const resetPasswordWithCode = async (
  username: string,
  newPass: string,
  verificationCode: string
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPass);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
/**
 * Provided with a verification code, user can reset the password
 */
export const resetPasswordFromLink = async (
  email:string,
  newPass: string,
  generatedPass:string,
  firstName:string,
  lastName:string
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const user = await Auth.signIn(email,generatedPass);
      if(!user){
        throw new Error("Verification Failed, Please Request Password Again")
      }
      await Auth.changePassword(user,generatedPass,newPass);
      await Auth.updateUserAttributes(user,{
        "custom:firstName":firstName,
        "custom:lastName":lastName
      })
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Returns {@link User} if the user is signed in and authenticated.
 * Null if ther user is not signed in.
 */
export const getCurrentAuthenticatedUser = (): Promise<User | null> => {
  return new Promise<User | null>((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        resolve({
          companyName: user.attributes?.name || user.attributes?.email,
          email: user.attributes?.email,
          verfied: user.attributes?.email_verified ?? true,
          firstName: user.attributes?.["custom:firstName"],
          lastName: user.attributes?.["custom:lastName"],
        });
      })
      .catch((err) => {
        console.error('some error getting the user we wanted', err)
        signOutUser();
        reject(err);
      });
  });
};

/**
 * Gets the JWT and also refreshes it in the back ground if needed.
 */
export const getJwtToken = async (): Promise<string> => {
  const session = await Auth.currentSession();

  return session.getAccessToken().getJwtToken();
};

/*
 * Returns CognitoUser if logged in otherwise null.
 */
export const loggedIn = async () => {
  return await Auth.currentUserInfo();
};


// reference: https://github.com/aws-amplify/amplify-js/issues/8632#issuecomment-1491513762
export const authorizeByToken = async (
  authData: {
    idToken: string,
    accessToken: string,
    refreshToken: string,
  },
  userConfig: {
    userPoolId: string,
    clientId: string
  }): Promise<CognitoUser | any> => {

    try {
      // create a CognitoAccessToken using the response accessToken
      const AccessToken = new
      AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: authData.accessToken,
      });

      // create a CognitoIdToken using the response idToken
      const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: authData.idToken,
      });

      // create a RefreshToken using the response refreshToken
      const RefreshToken = new
      AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: authData.refreshToken,
      });

      // create a session object with all the tokens
      const sessionData = {
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
      };

      // create the CognitoUserSession using the sessionData
      const session = new AmazonCognitoIdentity.CognitoUserSession(
        sessionData
      );

      // create an object with the UserPoolId and ClientId
      let poolData = {
        UserPoolId: userConfig.userPoolId,
        ClientId: userConfig.clientId,
      };

      // pass the poolData object to CognitoUserPool
      let userPool = new AmazonCognitoIdentity.CognitoUserPool(
        poolData
      );

      // create an object containing the username and user pool.
      // You can get the username from CognitoAccessToken object
      // we created above.
      let userData = {
        Username: AccessToken.payload.username,
        Pool: userPool,

      };

      // create a cognito user using the userData object
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        userData
      );


      // set the cognito user session w/ the CognitoUserSession
      cognitoUser.setSignInUserSession(session);

      // get the Amplify authenticated user
      return Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error("Error authenticating v2", error);
      return null;
    }
  };
import { types } from "../types";
import { FulfillmentLocation, Role, Shop } from "../../generated/graphql";

const {
  SHOPS_LIST,
  ACTIVE_SHOP_DOMAIN,
  SHOPS_LIST_FAILED,
  ACTIVE_USER_FAILED,
  ACTIVE_USER_ROLE,
  ACTIVE_USER_FULLNAME,
  QUERY_PARAM_SHOP_NAME,
  QUERY_PARAM_TOKEN_NAME,
  QUERY_PARAM_SKUBANA_CID,
  QUERY_PARAM_SKUBANA_CODE,
  CLEAR_QUERY_PARAMS,
  FC_LIST_FAILED,
  ACTIVE_FC_LIST,
  SELECTED_TAB,
  SET_IS_ADVANCE_VIEW,
  LOGOUT_ROOT,
  IS_THIS_DIRECT_SHOP,
  UPDATE_USER_INFO,
  UPDATE_INIT_SCORE_CARD,
  SHOW_FORGOT_PASSWORD,
  SET_LOCATIONS,
  VERIFY_LOCATION
} = types;

/** INITIAL STATES FOR ROOT REDUCER */
const initState: RootInitStateInterface = {
  shopList: [],
  activeShop: "",
  userRole: undefined,
  fullName: "",
  shopName: "",
  token: "",
  selectedTab: 0,
  btnsList: [
    { value: "Order", label: "Order" },
    { value: "Revenue", label: "Revenue" },
  ],
  valuationBtnsList: [
    { value: "ValuationOrder", label: "Order" },
    { value: "ValuationRevenue", label: "Revenue" },
  ],
  orderView: false,
  isDirectShop: true,
  userObject: {},
  initScoreCard: true,
  showPassword: false,
  locations: [],
};

export const rootReducer = (state = initState, action: ActionInterface) => {
  switch (action.type) {
    /** SUCCESS ACTION WILL EFFECT THROUGH BELOW CASES */
    case SHOPS_LIST:
      return {
        ...state,
        shopList: action.payload,
      };
    case ACTIVE_SHOP_DOMAIN:
      return {
        ...state,
        activeShop: action.payload,
      };
    case ACTIVE_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case ACTIVE_USER_FULLNAME:
      return {
        ...state,
        fullName: action.payload,
      };
    case QUERY_PARAM_SHOP_NAME:
      return {
        ...state,
        shopName: action.payload,
      };
    case QUERY_PARAM_TOKEN_NAME:
      return {
        ...state,
        token: action.payload,
      };
    case QUERY_PARAM_SKUBANA_CID:
      return {
        ...state,
        skubana_cid: action.payload,
      };
    case QUERY_PARAM_SKUBANA_CODE:
      return {
        ...state,
        skubana_code: action.payload,
      };
    case ACTIVE_FC_LIST:
      return {
        ...state,
        activeFc: action.payload,
      };
    case SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_IS_ADVANCE_VIEW:
      return {
        ...state,
        orderView: action.payload,
      };
    case LOGOUT_ROOT:
      return {
        ...state,
        shopList: [],
        activeShop: "",
        userRole: undefined,
        fullName: "",
        shopName: "",
        token: "",
        selectedTab: 0,
        orderView: false,
        isDirectShop: true,
      };
    case IS_THIS_DIRECT_SHOP:
      return {
        ...state,
        isDirectShop: action.payload,
      };
    case SHOW_FORGOT_PASSWORD:
      return {
        ...state,
        showPassword: action.payload
      }
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload
      }
    case VERIFY_LOCATION:
      return {
        ...state,
        // Replace the location with the updated one
        locations: state.locations?.map((loc) => loc.id === action.payload.id ? action.payload : loc)
      }

    /** FAILED ACTION WILL EFFECT THROUGH BELOW CASES */
    case SHOPS_LIST_FAILED:
      return {
        ...state,
      };

    case ACTIVE_USER_FAILED:
      return {
        ...state,
        fullName: "",
        userRole: undefined,
      };

    case CLEAR_QUERY_PARAMS:
      return {
        ...state,
        shopName: "",
        token: "",
      };

    case FC_LIST_FAILED:
      return {
        ...state,
      };

    case UPDATE_INIT_SCORE_CARD:
      return {
        ...state,
        initScoreCard: action.payload,
      };

    case UPDATE_USER_INFO:
      return {
        ...state,
        userObject: action.payload,
      };

    /** DEFAULT CASE */
    default:
      return state;
  }
};

interface ActionInterface {
  type: string;
  payload: any;
}

export interface RootInitStateInterface {
  shopList: Array<Shop>;
  activeShop: string;
  userRole: undefined | Role;
  fullName: string;
  shopName: string;
  token: string;
  selectedTab: number;
  btnsList: Array<BtnList>;
  valuationBtnsList: Array<BtnList>;
  orderView: boolean;
  isDirectShop: boolean;
  userObject: any;
  initScoreCard: boolean;
  showPassword: boolean;
  locations?: Array<FulfillmentLocation>
}

interface BtnList {
  value: string;
  label: string;
}

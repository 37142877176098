import { Button } from "@mui/material";
import { LoginFormProps } from "../../styles";
import Title from "../../Main/Title/Index";

function Index(props: LoginFormProps) {
  return (
    <div className="p-7">
      <Title style={{ marginBottom: 39 }}>
        <>
          Access your account
        </>
      </Title>
      <div style={{ marginBottom: 30 }}>
        <Button
          onClick={() => {
            const redirectURL = 'https://apps.shopify.com/capabl-integration';
            window.location.href = redirectURL;
          }}
        >
          Sign up or Login with Shopify &rarr;
        </Button>
      </div>
    </div>
  );
}

export default Index;


import { SelectChangeEvent, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  imagesBox: {
    width: 700,
  },
  rightCard: {
    borderRadius: "8px !important",
    position: "relative",
  },
  accountBtn: {
    marginTop: "50px !important",
    marginBottom: "10px !important",
  },
  step1Card: {
    width: 389,
    height: 72,
    background: "rgba(231, 245, 251, 0.25)",
  },
  activeCardStep1: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardStep1: {
    border: "1px solid transparent",
  },
  step1Titel: {
    marginBottom: "43px !important",
  },
  stepperRootStep1: {
    width: 389,
  },
  stepperRoot: {
    width: 323,
  },
  stepperButton: {
    fontSize: 13,
  },
  textField: {
    minWidth: 295,
    width: "100%",
    marginBottom: "21px !important",
  },
  textFieldPassword: {
    minWidth: 295,
    width: "100%",
    marginBottom: "5px !important",
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  timePickerRoot: {
    "& .MuiPickersLayout-toolbar": {
      "& .MuiTypography-overline": {
        display: "none !important",
      },
    },
    "& .MuiPickersToolbar-content": {
      justifyContent: "center",
      "& .MuiTimePickerToolbar-hourMinuteLabel": {
        "& .MuiButton-root": {
          backgroundColor: theme.palette.primary.light,
          borderRadius: 5,
          padding: "5px 15px",
          "& .MuiTypography-h3": {
            color: "#fff",
          },
        },
        "& .MuiTimePickerToolbar-separator": {
          color: theme.palette.primary.light,
          marginBottom: 8,
        },
      },
      "& .MuiTimePickerToolbar-ampmSelection": {
        marginRight: 0,
        "& .MuiButton-root": {
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "inherit",
            // padding: "3px 5px",
            "& .MuiTypography-subtitle2": {
              // color: "#fff",
              padding: "3px 10px",
            },
          },
          backgroundColor: "#E0E0E0",
          borderRadius: "inherit",
          "& .MuiTypography-subtitle2": {
            // color: "#fff",
            padding: "3px 10px",
          },
        },
        "& button:nth-child(1)": {
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
        },
        "& button:nth-child(2)": {
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5,
        },
      },
    },
  },
  toggle: {
    "&.MuiToggleButtonGroup-root .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    "& .MuiToggleButton-root": {
      height: "35px",
      borderRadius: "10px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      paddingRight: "30px",
      paddingLeft: "30px",
    },
  },
  monthly: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  annually: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  forgotPasswordContainer: {
    maxWidth: "390px",
    cursor: "pointer",
    width: "100%",
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export interface StepsProps {
  formik: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step?: number;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>,
    name: string
  ) => void;
}

export interface LoginFormProps {
  formik: any;
}

export interface Plan {
  name: string;
  subTitle: string;
  price: string;
  offers: Array<Offer>;
  annualPrice: string;
}

interface Offer {
  name: string;
}

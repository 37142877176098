import { EChartsOption } from "echarts";
import moment from "moment";
import { BiTableId } from "../../../../../generated/graphql";
import { decimalParser } from "../../../../../utils/helper";

export const colors = [
  // "#7565A1",
  "#9C27B0",
  "#236194",
  "#9084ea",
  "#1D2966",
  "#519E8A",
  "#5470c6",
  "#9a60b4",
  "#ee6666",
  "#fc8452",
  "#fac858",
  "#73c0de",
  "#3ba272",
  "#ea7ccc",
];

export function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(2) + "k"
    : Math.sign(num) * Math.abs(num);
}

export const graphLoaderOptions = { maskColor: "transparent" };

export const chartsBtnsList = [
  { value: "dailyIntervel", label: "Weekly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarter", label: "Quarterly" },
];

export function graphOptions(
  title: string,
  list: boolean,
  gridCols: number,
  isLessLg: boolean,
  isZoom: boolean,
  max: number,
  start: number,
  imgTitle: string
): EChartsOption {
  return {
    color: colors,
    title: {
      text: "",
      textVerticalAlign: "top",
      textAlign: "left",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: function (params: any) {
        let output: string[] | string = `${params[0].axisValueLabel ?? params[0].axisValue}`;
        if (
          title === "Zone Distribution" ||
          title === "Shipment Exception Per City" ||
          title === "Shipment Exception" ||
          title === "Order accuracy <> Complaints per 1000 orders"
        ) {
          output += "<br/>";
        } else {
          output = output.split(" ");
          output =
            moment(`${+output[2] + 1}/${output[1]}/${output[3]}`).format("DD MMM YY") + "<br/>";
        }

        params.map((param: any) => {
          if (+param.data > 0) {
            output += `<div style='background-color:${
              param.color
            }; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 5px'></div>
              ${param.seriesName}: ${decimalParser(param.data)}<br />`;
          }
          return param;
        });
        return output;
      },
    },
    dataZoom: [
      {
        type: "inside",
        height: 10,
        startValue: "",
        id: title,
        labelFormatter: function () {
          return "";
        },
      },
    ],
    grid: {
      right: gridCols === 12 && isLessLg === false ? "15%" : "2%",
      width: gridCols === 12 && isLessLg === false ? "80%" : "88%",
    },
    toolbox: {
      showTitle: false,
      feature: {
        dataView: { show: false, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true, name: imgTitle.split(" ").join("_") },
        dataZoom: { show: false },
        magicType: { show: true, type: ["line", "bar"] },
      },
      tooltip: {
        show: true,
        formatter: function (param: any) {
          return "<div>" + param.title + "</div>"; // user-defined DOM structure
        },
        backgroundColor: "#7565A1",
        padding: [5, 10, 5, 10],
        textStyle: {
          fontSize: 12,
          color: "#ffffff",
        },
        extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);", // user-defined CSS styles
      },
    },
  };
}

export function legend(
  data: Array<string>,
  list: boolean,
  gridCols: number,
  isLessLg: boolean,
  isLessXL: boolean
): EChartsOption {
  return {
    legend: {
      ...(gridCols === 12 &&
        isLessLg === false && {
          orient: "vertical",
          type: "scroll",
          // left: "right",
          right: 10,
          top: 50,
          bottom: 20,
          formatter: function (name) {
            if (name.length > 25 && isLessXL === false) {
              return name.slice(0, 25) + "...";
            } else if (name.length > 20 && isLessXL === true) {
              return name.slice(0, 20) + "...";
            } else {
              return name;
            }
          },
        }),
      ...((gridCols === 6 || isLessLg === true) && {
        orient: "horizontal",
        type: "scroll",
        bottom: 0,
        left: 10,
      }),
      // to avoid showing legends with no text
      data: data.filter((d: string) => d !== ""),
    },
  };
}

export function xAxis(data: string[] | undefined, period: string, title: string): EChartsOption {
  return {
    xAxis: [
      {
        type: "category",
        nameLocation: "middle",
        nameTextStyle: {
          align: "right",
          verticalAlign: "bottom",
          fontSize: 16,
        },
        nameGap: 45,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          formatter: (value: any) => {
            if (
              title === "Zone Distribution" ||
              title === "Shipment Exception Per City" ||
              title === "Shipment Exception" ||
              title === "Order accuracy <> Complaints per 1000 orders"
            ) {
              return value;
            }

            const date = value.split(" ");
            return moment(`${+date[2] + 1}/${date[1]}/${date[3]}`).format("DD MMM YY");
          },
        },
        data: data,
      },
    ],
  };
}

export function yAxisObject(yAxisTitle: string, scale: boolean): EChartsOption {
  return {
    type: "value",
    position: "left",
    name: yAxisTitle.toUpperCase(),
    nameLocation: "center",
    nameTextStyle: {
      align: "center",
      verticalAlign: "bottom",
      fontSize: 16,
    },
    axisLabel: {
      formatter: (value: any) => {
        return kFormatter(+value);
      },
    },
    scale: !scale,
    nameGap: 40,
    nameRotate: 90,
    axisLine: {
      show: true,
    },
    axisTick: {
      show: true,
    },
  };
}

export function yAxis(data: any[]): EChartsOption {
  return {
    yAxis: data,
  };
}

export function seriesObject(
  name: string,
  type: string,
  data: Array<number>,
  stack: boolean,
  stackType: string
): EChartsOption {
  return {
    name: name,
    type: type,
    data: data,
    barMaxWidth: 23,
    // smooth: true,
    ...(stack && { stack: stackType }),
    emphasis: {
      focus: "series",
    },
  };
}

export function series(data: any[]): EChartsOption {
  return {
    series: data,
  };
}
export function reportsChartsArray() {
  return [
    {
      id: "Box-0",
      order: 0,
      trendSlice: [],
      labels: ["Order"],
      title: "Order by Day (average)",
      graphTypes: ["line"],
      stack: false,
      yAxisTitle: "Order",
      biQuery: BiTableId.AvgOrderPerDay,
      distributionQuery: [BiTableId.AvgOrderPerDay],
      reportType: "default",
    },
    {
      id: "Box-1",
      order: 1,
      trendSlice: [],
      labels: ["Shipment"],
      title: "Shipment by Day (average)",
      graphTypes: ["line"],
      stack: false,
      yAxisTitle: "Shipment",
      biQuery: BiTableId.AvgShipmentPerDay,
      distributionQuery: [BiTableId.AvgShipmentPerDay],
      reportType: "default",
    },
    {
      id: "Box-2",
      order: 2,
      trendSlice: [],
      labels: ["", ""],
      stack: false,
      title: "Order Value",
      graphTypes: ["bar", "line"],
      yAxisTitle: "Order Value ($)",
      biQuery: BiTableId.AvgOrderValue,
      distributionQuery: [BiTableId.AvgOrderValue, BiTableId.MdnOrderValue],
      reportType: "default",
    },
    {
      id: "Box-3",
      order: 3,
      trendSlice: [],
      labels: ["", ""],
      stack: false,
      title: "Basket Size",
      graphTypes: ["bar", "line"],
      yAxisTitle: "Basket Size",
      biQuery: BiTableId.AvgBasketSize,
      distributionQuery: [BiTableId.AvgBasketSize, BiTableId.MdnBasketSize],
      reportType: "default",
    },
    {
      id: "Box-4",
      order: 4,
      trendSlice: [""],
      labels: ["", ""],
      stack: false,
      title: "Order to Shipment (day)", // "Quality Issues / Order to Shipment",
      graphTypes: ["bar", "line"],
      yAxisTitle: "Order By FCs",
      biQuery: BiTableId.OrderToShipment,
      distributionQuery: [BiTableId.OrderToShipment],
      reportType: "default",
    },
    // {
    //   id: "Box-5",
    //   order: 5,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Orders on Hold by SKUs (top 10)",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "No of Orders on Hold",
    //   biQuery: BiTableId.DOsBySku,
    //   distributionQuery: [BiTableId.DOsBySku],
    //   reportType: "default",
    // },
    {
      id: "Box-6",
      order: 6,
      trendSlice: [],
      labels: ["", ""],
      stack: true,
      title: "Shipments by FC",
      graphTypes: ["bar"],
      stackType: "bar",
      yAxisTitle: "Shipments by FC",
      biQuery: BiTableId.ShipmentByFc,
      distributionQuery: [BiTableId.ShipmentByFc],
      reportType: "default",
    },
    {
      id: "Box-7",
      order: 7,
      trendSlice: [],
      labels: ["", ""],
      stack: true,
      title: "Shipments by Parcel Product", // "Quality Issues / Order to Shipment",
      graphTypes: ["bar"],
      stackType: "bar",
      yAxisTitle: "Shipments by Parcel Product",
      biQuery: BiTableId.ShipmentByTrac,
      distributionQuery: [BiTableId.ShipmentByTrac],
      reportType: "default",
    },
    {
      id: "Box-8",
      order: 8,
      trendSlice: [],
      labels: ["", ""],
      stack: true,
      title: "Top 5 selling SKUs", // "Quality Issues / Order to Shipment",
      graphTypes: ["bar"],
      stackType: "bar",
      yAxisTitle: "Top 5 SKUs",
      biQuery: BiTableId.ShipmentBySku,
      distributionQuery: [BiTableId.ShipmentBySku],
      reportType: "default",
    },
    // {
    //   id: "Box-10",
    //   order: 10,
    //   trendSlice: [""],
    //   labels: ["", ""],
    //   stack: false,
    //   title: "Order to Delivered (day)", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar", "line"],
    //   yAxisTitle: "Order By FCs",
    //   biQuery: BiTableId.OrderToDelivered,
    //   distributionQuery: [BiTableId.OrderToDelivered],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-11",
    //   order: 11,
    //   trendSlice: [""],
    //   labels: ["", ""],
    //   stack: false,
    //   title: "Shipped to Delivered (day)", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar", "line"],
    //   yAxisTitle: "Order By FCs",
    //   biQuery: BiTableId.ShippedToDelivered,
    //   distributionQuery: [BiTableId.ShippedToDelivered],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-12",
    //   order: 12,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   title: "Shipping Charges", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Shipping Charges",
    //   stackType: "bar",
    //   biQuery: BiTableId.ParcelExpense,
    //   distributionQuery: [BiTableId.ParcelExpense],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-13",
    //   order: 13,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Average Shipment Weights (lb)", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Average Shipment Weight per Parcel",
    //   biQuery: BiTableId.AvgShipmentWeights,
    //   distributionQuery: [BiTableId.AvgShipmentWeights],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-14",
    //   order: 14,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Median Shipment Weights (lb)", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Median Shipment Weight per Parcel",
    //   biQuery: BiTableId.MdnShipmentWeights,
    //   distributionQuery: [BiTableId.MdnShipmentWeights],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-15",
    //   order: 15,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Non Domestic Shipments", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Non Domestic Shipments",
    //   biQuery: BiTableId.NonDomesticShipments,
    //   distributionQuery: [BiTableId.NonDomesticShipments],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-16",
    //   order: 16,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Shipment Exception", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "SHIPMENT EXCEPTION",
    //   isTimeTabs: false,
    //   xAxisTitle: "Reasons",
    //   biQuery: BiTableId.ShipmentException,
    //   distributionQuery: [BiTableId.ShipmentException],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-17",
    //   order: 17,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Shipment Exception Per City", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Shipments",
    //   isTimeTabs: false,
    //   xAxisTitle: "Cities",
    //   biQuery: BiTableId.ShipmentExceptionPerCity,
    //   distributionQuery: [BiTableId.ShipmentExceptionPerCity],
    //   reportType: "default",
    //   type: "shipment",
    // },
    // {
    //   id: "Box-18",
    //   order: 18,
    //   trendSlice: [],
    //   labels: ["", ""],
    //   stack: true,
    //   stackType: "bar",
    //   title: "Zone Distribution", // "Quality Issues / Order to Shipment",
    //   graphTypes: ["bar"],
    //   yAxisTitle: "Shipments",
    //   isTimeTabs: false,
    //   xAxisTitle: "Zones",
    //   biQuery: BiTableId.ZoneDistribution,
    //   distributionQuery: [BiTableId.ZoneDistribution],
    //   reportType: "default",
    //   type: "shipment",
    // },
  ];
}

export function orderAccuracy() {
  return [
    {
      id: "Box-1",
      order: 1,
      trendSlice: [],
      labels: ["", ""],
      stack: false,
      title: "Number of Complaints",
      graphTypes: ["bar", "line"],
      yAxisTitle: "Number of Complaints",
      biQuery: BiTableId.ComplaintsCountPerFc,
      distributionQuery: [BiTableId.ComplaintsCountPerFc],
      reportType: "default",
    },
    {
      id: "Box-2",
      order: 2,
      trendSlice: [],
      labels: ["", ""],
      stack: true,
      title: "Complaints Per Reason", // "Quality Issues / Order to Shipment",
      graphTypes: ["bar"],
      stackType: "bar",
      yAxisTitle: "Complaints Count",
      biQuery: BiTableId.ComplaintsPerReason,
      distributionQuery: [BiTableId.ComplaintsPerReason],
      reportType: "default",
    },
    {
      id: "Box-3",
      order: 3,
      trendSlice: [],
      labels: ["", ""],
      stack: false,
      title: "Order Accuracy Percentage",
      graphTypes: ["line"],
      yAxisTitle: "Order Accuracy (%)",
      biQuery: BiTableId.AccuracyPercentageFc,
      distributionQuery: [BiTableId.AccuracyPercentageFc],
      reportType: "default",
    },
    {
      id: "Box-4",
      order: 5,
      trendSlice: [],
      labels: ["", ""],
      stack: true,
      title: "Order accuracy <> Complaints per 1000 orders", // "Quality Issues / Order to Shipment",
      graphTypes: ["bar"],
      stackType: "bar",
      yAxisTitle: "Complaints per 1000 orders",
      maxXaxis: 1, // to differentiate graph having cellId without week/month/quarter
      xAxisTitle: "Complaints",
      biQuery: BiTableId.QualityIssuesPercPer_1000,
      distributionQuery: [BiTableId.QualityIssuesPercPer_1000],
      reportType: "default",
      type: "shipment",
    },
  ];
}

export interface TimeInterface {
  weekly: string[];
  monthly: string[];
  quarter: string[];
  dailyInternal: string[];
}

export interface BoxInterface {
  id: string;
  order: number;
  trendSlice: any[];
  time: TimeInterface;
  labels: string[];
  stack: boolean;
  title: string;
  graphTypes: string[];
  yAxisTitle: string;
  biQuery: BiTableId;
  reportType: string;
  type?: string;
  stackType?: string;
  maxXaxis?: number;
  isTimeTabs?: boolean;
  xAxisTitle?: string;
}

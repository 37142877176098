import loadable from "@loadable/component";
import { Box, createTheme, LinearProgress, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import AuthAfter from "./AuthAfter";
import AuthBefore from "./AuthBefore";
import ShopifyOTP from "./Components/Dashboard/ShopifyOTP";
import ForgotPassword from "./Components/Onboarding/ForgotPassword";
import Logins from "./Components/NewHome/Login/Index";
import Dashboards from "./Components/NewHome/Login/Index";
import UserWelcome from "./Components/Onboarding/UserWelcome";
import { OnboardingUserInput, Role } from "./generated/graphql";
import background from "./Resources/misc/background.png";
import { SuccessToaster } from "./toast";
import { authorizeByToken, configureAuth, getCurrentAuthenticatedUser } from "./utils/AuthWrapper";
import { fetchUserByEmailFromDB } from "./utils/providerServices";
import SkubanaIntegration from "./Components/Onboarding/Apps/Skubana";
import Reports from "./Components/Dashboard/scorecarding/Reports/index";
// import * as amplitude from "@amplitude/analytics-browser";
import { SHOPIFY_V2_SEARCH_PARAM, USER_EMAIL } from "./utils/const";
import SplashLoader from "./splashLoader";
import { theme as defaultTheme } from "./NewTheme";
import { Crisp } from "crisp-sdk-web";
import crypto from 'crypto';

// import usePageTracking from "./useTracking";
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-T8BB780Y60");

Crisp.configure("cfe83a7e-57e5-47cc-9920-7f88c57b6d43");

// amplitude.init(process.env.REACT_APP_AMP_BROWSER_KEY);
const DashboardAssembly = loadable(() => import("./Components/Dashboard/DashboardAssembly"));
const OnboardContainer = loadable(() => import("./Components/Onboarding/OnboardingContainer"));
const Signup = loadable(() => import("./Components/NewHome/Signup/Index"));


function decryptMessage(encryptedText: string, passphrase: string): string {
  const decipher = crypto.createDecipher('aes-256-cbc', passphrase);
  let decryptedMessage = decipher.update(encryptedText, 'hex', 'utf8');
  decryptedMessage += decipher.final('utf8');
  return decryptedMessage.toString();
}

const getNewCreds = (querystring: string = '') => {
  const params = new URLSearchParams(querystring);
  const newCreds = params.get(SHOPIFY_V2_SEARCH_PARAM);

  if (newCreds) {
    const value = decryptMessage(newCreds, '30eb3055daf50e95010300e28b48d1e7')
    const [idToken, refreshToken, accessToken] = value.split(":");

    const shop = params.get('aaa')

    return { idToken, refreshToken, accessToken, user: null, shop };
  }

  return null;
}


/**
 * @description This is the main component for the app
 * @param {void}
 * @returns {JSX}
 */

function App() {
  // usePageTracking();
  const dispatch = useDispatch();
  const [fetchedUser, setFetchedUser] = useState<OnboardingUserInput | undefined>(undefined);
  const { onboarding }: any = useSelector((state: any) => state);
  const [path, setPath] = useState("");
  const [loaded, setLoaded] = useState(false);

  const qShop = useRef("");
  const qToken = useRef("");
  const shopType = useRef("");
  const skubanaCid = useRef("");
  const skubanaCode = useRef("");
  useEffect(() => {
    const params = window.location.href.split("?")[1];
    if (params) {
      const query = params.split("&");
      query.forEach((q) => {
        const val = q.split("=");


        if(val[0] === SHOPIFY_V2_SEARCH_PARAM) {
          localStorage.setItem(SHOPIFY_V2_SEARCH_PARAM, val[1]);
        }


        if (val[0] === "notif") {
          SuccessToaster(val[1]);
        } else if (val[0] === "shop") {
          qShop.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SHOP_NAME",
            payload: val[1],
          });
        } else if (val[0] === "otp") {
          qToken.current = val[1];
          dispatch({
            type: "QUERY_PARAM_TOKEN_NAME",
            payload: val[1],
          });
        } else if (val[0] === "type") {
          shopType.current = val[1];
          dispatch({ type: "SET_SHOP_TYPE", payload: val[1] });
        } else if (val[0] === "cid") {
          skubanaCid.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SKUBANA_CID",
            payload: val[1],
          });
        } else if (val[0] === "code") {
          skubanaCode.current = val[1];
          dispatch({
            type: "QUERY_PARAM_SKUBANA_CODE",
            payload: val[1],
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loaded && !["/auth", "/skubana"].includes(window.location.pathname)) {
      async function loadApp(): Promise<void> {
        try {
          const params = window.location.href.split("?")[1];
          configureAuth(true);

          let user;
          if (params) {
            const newCreds = getNewCreds(params);

            if (newCreds) {
              const { idToken, refreshToken, accessToken } = newCreds;

              const cognitoResponse = await authorizeByToken({idToken, refreshToken, accessToken}, {
                userPoolId: 'us-east-2_YqvgT30yb',
                clientId: '2t3f68e3g7q9ioah6bdlt2ajg0',
              });

              const currentUrl = window.location.href;

              const updatedUrl = currentUrl.split('?')[0];

              // Update the URL in the address window
              window.history.replaceState({}, document.title, updatedUrl);

              user = {
                companyName: cognitoResponse.attributes.name,
                email: cognitoResponse.attributes.email,
                verfied: cognitoResponse.attributes.email_verified,
                firstName: cognitoResponse.attributes["custom:firstName"],
                lastName: cognitoResponse.attributes["custom:lastName"],
              }

            }
          } else {
            user = await getCurrentAuthenticatedUser();
          }


          if (user !== null) {
            let userData: OnboardingUserInput = {
              comp_name: user.companyName,
              email: user.email,
              created_at: "",
              cust_id: 0,
              role: Role.Admin,
              id: -1,
              status: "ACTIVE",
              deleted_at: null,
              firstName: user.firstName,
              lastName: user.lastName,
            };
            try {
              let res = await fetchUserByEmailFromDB();
              if (res !== null && user !== null) {
                setFetchedUser({
                  comp_name: user.companyName,
                  email: user.email,
                  created_at: res.createdAt,
                  cust_id: res.customerId,
                  role: res.role,
                  id: res.id,
                  status: "ACTIVE",
                  deleted_at: null,
                  firstName: res.firstName,
                  lastName: res.lastName,
                });
                if (qShop.current !== "" && qToken.current !== "" && qShop && qToken) {
                  isShopInprocess();
                }
                setPath("/dashboard");
                setLoaded(true);
              } else {
                // if user does not exit in db then go to onboarding
                setFetchedUser(userData);
                setPath(`/onboarding${window.location.search}`);
                setLoaded(true);
              }
            } catch (error) {
              // if user does not exit in db then go to onboarding
              console.error(error);
              setFetchedUser(userData);
              setPath(`/onboarding${window.location.search}`);
              setLoaded(true);
            }
          } else {
            // User redirected to sign in
            setPath(`/${window.location.search}`);
            setLoaded(true);
          }
        } catch (e) {
          // User redirected to sign in
          if (window.location.href.includes("?")) {
            setPath(`/?${window.location.href.split("?")[1]}`);
          } else if (window.location.href.split("#/")[1] === "signup") {
            setPath(`/signup`);
          } else {
            setPath(`/${window.location.search}`);
          }
          setLoaded(true);
        }
      }

      loadApp();
    } else if (window.location.pathname === "/auth") {
      setPath("/auth");
      setLoaded(true);
    } else if (window.location.pathname === "/skubana") {
      setPath("/skubana");
      setLoaded(true);
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  function isShopInprocess() {
    if (qShop.current !== "" && qToken.current !== "" && qShop && qToken) {
      dispatch({
        type: "SELECTED_TAB",
        payload: 6,
      });
    }
  }

  useEffect(() => {
    if (
      ((fetchedUser && fetchedUser.email) || onboarding.onboardingUser) &&
      localStorage.getItem(USER_EMAIL)
    ) {
      const email = fetchedUser ? fetchedUser.email : onboarding.onboardingUser.email;
      localStorage.setItem("onboard_" + email, JSON.stringify(onboarding));
    }
  }, [onboarding]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ width: "100%" }}>
      {!loaded && <LinearProgress />}
      {loaded && <LoadRoutes path={path} user={fetchedUser} isShopInprocess={isShopInprocess} />}
    </Box>
  );
}

/**
 * @description this component is used to load top level routes
 * @param props
 * @returns
 */

function LoadRoutes(props: {
  path?: string;
  user?: OnboardingUserInput;
  isShopInprocess: () => void;
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [generatedPass, setGeneratedPass] = useState<string>("");
  const [landingUser, setLandingUser] = useState<string>("");
  const [showVerif, setShowVerif] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<string>("");

  useEffect(() => {
    dispatch({ type: "SET_SELECTED_FC", payload: [] });
    dispatch({ type: "SET_SHOP_STATUS", payload: "init" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let generatedPassword: string[] = [];
    let userEmail: string[] = [];
    let verifCode: string[] = [];
    let planFromSite: string[] = [];
    let isAdvanceView: string[] = [];

    // to handle multiple parameters. e.g. localhost?param1=value1&param2=value2&param3=value3, param2 and param3 will be in search.
    if (location.search) {
      generatedPassword = new URLSearchParams(location.search).getAll("generated");
      userEmail = new URLSearchParams(location.search).getAll("user");
      verifCode = new URLSearchParams(location.search).getAll("code");
      planFromSite = new URLSearchParams(location.search).getAll("plan");
      isAdvanceView = new URLSearchParams(location.search).getAll("view");
    } else if (location.hash) {
      let generated = getParameterByName("generated", location.hash);
      if (generated) generatedPassword.push(generated);

      let user = getParameterByName("user", location.hash);
      if (user) userEmail.push(user);

      let code = getParameterByName("code", location.hash);
      if (code) verifCode.push(code);

      let plan = getParameterByName("plan", location.hash);
      if (plan) planFromSite.push(plan);

      let view = getParameterByName("view", location.hash);
      if (view) isAdvanceView.push(view);
    }
    console.log(verifCode, "verifCode");
    if (generatedPassword.length > 0 && userEmail.length > 0) {
      //Values are present for landing user
      setGeneratedPass(generatedPassword[0]);
      setLandingUser(userEmail[0]);
      setShowVerif(true);
      dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: true });
    } else if (verifCode.length > 0) {
      setVerifyCode(verifCode[0]);
      dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: true });
    } else if (planFromSite.length > 0) {
      localStorage.setItem("capablPlan", planFromSite[0]);
    } else if (isAdvanceView.length > 0) {
      //land it on advance view
      localStorage.setItem("view", "Advance");
    }
  }, [location.search, location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Gets query string parameter from the URL.
   */
  function getParameterByName(name: string, url: string) {
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function handleResetSuccessfull() {
    SuccessToaster("Updated Successfully! Please login with your new password");
    dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: false });
    if (verifyCode !== "") {
      //Password reset has been performed reset the top url to base site url
      window.location.href = window.location.href.split("?")[0];
    }
  }

  /**
   * Handle the Forgot Password flow
   */
  function handleForgotPass() {
    SuccessToaster("Please check your email for password reset link");
    dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: false });
  }
  /**
   * Hide forgot password Modal
   */
  function hideForgotPass() {
    dispatch({ type: "SHOW_FORGOT_PASSWORD", payload: false });
    if (landingUser !== "") {
      //Password reset has been performed reset the top url to base site url
      window.location.href = window.location.href.split("?")[0];
    }
  }

  return (
    <div className="mx-auto" style={{ maxWidth: 1600 }}>
      {props.path === "/auth" ? (
        // <BrowserRouter>
        <Route path="/auth">
          <ShopifyOTP />
        </Route>
      ) : (
        // </BrowserRouter>
        // <BrowserRouter>
        <Switch>
          <AuthAfter path="/about">
            <About />
          </AuthAfter>
          <AuthAfter path={`/onboarding`}>
            <OnboardContainer />
          </AuthAfter>
          <AuthAfter path="/dashboard">
            <DashboardAssembly />
          </AuthAfter>
          <AuthAfter path="/skubana">
            <SkubanaIntegration />
          </AuthAfter>
          <Route path="/welcome">
            <Welcome />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <AuthBefore path="/signup">
            <Suspense fallback={<SplashLoader />}>
              <Signup />
            </Suspense>
          </AuthBefore>
          <AuthBefore path="/">
            <Suspense fallback={<SplashLoader />}>
              <ThemeProvider theme={defaultTheme}>
                <Logins />
              </ThemeProvider>
            </Suspense>
          </AuthBefore>
          <Route path="/dashboards">
            <Suspense fallback={<SplashLoader />}>
              <ThemeProvider theme={defaultTheme}>
                <Dashboards />
              </ThemeProvider>
            </Suspense>
          </Route>
        </Switch>
        // </BrowserRouter>
      )}
      <ThemeProvider theme={defaultTheme}>
        <ForgotPassword
          handleResetSuccess={handleResetSuccessfull}
          verificationCode={verifyCode}
          handleForgotPassword={handleForgotPass}
          user={landingUser}
          generatedPass={generatedPass}
          showVerif={showVerif}
          onModalHide={hideForgotPass}
          onPasswordForgotDone={hideForgotPass}
        />
      </ThemeProvider>
    </div>
  );
}

function Welcome() {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftBox}></div>
      <div className={classes.rightBox}>
        <UserWelcome />
      </div>
    </div>
  );
}

function About() {
  return <h2>About</h2>;
}

export default App;

const theme = createTheme();
const useStyles = makeStyles({
  mainContainer: {
    width: "100%",
    display: "flex",
  },
  rightBox: {
    backgroundColor: "#2C1D4F",
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0%",
    },
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "35%",
    padding: "80px 0px",
  },
  leftBox: {
    width: "35%",
    [theme.breakpoints.down("md")]: {
      width: "0%",
    },
    backgroundImage: `url(${background})`,
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "fixed",
  },
  centerBox: {
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    display: "flex",
    marginTop: "4%",
  },
});

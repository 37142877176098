import React from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { RoutesType } from "../../../Components/Onboarding/FCRouting";
import { array_countries, array_states } from "../../../utils/globalConstants";


type ChipDialogProps = {
  array_defaults: string[];
  onChange: (array_zips: Array<any>) => void;
  routesType: RoutesType;
};

export default function ChipDialog(props: ChipDialogProps) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: any, n: string) => {
    setInputValue(n);
  };

  let {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    defaultValue: props.array_defaults,
    autoComplete: true,
    multiple: true,
    options:
      props.routesType === RoutesType.STATES
        ? array_states
        : props.routesType === RoutesType.COUNTRIES
        ? array_countries
        : [],
    getOptionLabel: (option) => option,
    inputValue: inputValue,
    onInputChange: handleInputChange,
    freeSolo: props.routesType === RoutesType.ZIP,
    isOptionEqualToValue: (option, value) => option === value,
  });

  React.useEffect(() => {
    props.onChange(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          onPaste={(e: any) => {
            const pastedStates: string[] = e.clipboardData.getData("Text").split(":");
            pastedStates.map((v: string, i: number) => {
              if (array_states.some((s) => s === v) && !value.some((s) => s === v)) {
                value.push(v);
              }
              return null;
            });
            props.onChange(value);
            setTimeout(() => {
              setInputValue("");
            }, 300);
          }}
          ref={setAnchorEl}
          style={{ display: "flex", alignItems: "center", padding: "0px" }}
          className={focused ? "focused" : ""}
          onChange={() => {
            props.onChange(value);
          }}
        >
          {value.map((option: string, index: number) => {
            return (
              <Chip
                variant="outlined"
                className={classes.tag}
                deleteIcon={
                  <span aria-label={option} className={classes.redCross}>
                    <Close fontSize="small" />
                  </span>
                }
                label={option}
                {...getTagProps({ index })}
              />
            );
          })}

          <input
            maxLength={props.routesType === RoutesType.ZIP ? 2 : undefined}
            placeholder={
              props.routesType === RoutesType.ZIP
                ? "Type the first two digits of the zip code. To add multiple zip codes press Enter"
                : ""
            }
            {...getInputProps()}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option: option as string, index })}>
              <span>{option}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

const Root = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  height:100%;
  maxHeight: 50px;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
    height: 47px;
    box-sizing: border-box;
    padding: 12px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props: { [x: string]: any; label: any; onDelete: any }) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#F1F2F8",
    height: "33px",
    color: "#1C4A70",
    fontFamily: "ITC Avant Garde Gothic Std Book",
    fontSize: "15px",
    margin: "5px",
  },
  redCross: {
    color: "gray",
    fontSize: "10px",
  },
});

import { Redirect, Route } from "react-router-dom";
import { USER_EMAIL, USER_STATUS } from "./utils/const";
import { signOutUser } from "./utils/AuthWrapper";

interface AuthAfterProps {
  path: string;
  children: any;
}

let onboaringUrls = ["/skubana", "/onboarding"];


/**
 * @description this component is authentitated route for the user after login
 * @param {AuthAfterProps}
 * @returns {JSX}
 */

function AuthAfter({ children, path }: AuthAfterProps) {
  const email = localStorage.getItem(USER_EMAIL);
  let user_status = "";
  if (email && localStorage.getItem(USER_STATUS)) {
    user_status = JSON.parse(localStorage.getItem(USER_STATUS) ?? "");
    if (!onboaringUrls.includes(path) && user_status === "inactive") {
      handleSignup();
    }
  } else {
    handleSignup();
  }

  function handleSignup() {
    signOutUser().then(() => {
      localStorage.removeItem(USER_EMAIL);
      localStorage.removeItem(USER_STATUS);
    });
  }
  return (
    <>
      {user_status === "active" && path !== "/onboarding" ? (
        <Route path={path}>{email ? children : <Redirect to="/" />}</Route>
      ) : user_status === "inactive" && path === "/onboarding" ? (
        <Route path={path}>{email ? children : <Redirect to="/" />}</Route>
      ) : path === "/skubana" ? (
        <Route path={path}>{email ? children : <Redirect to="/skubana" />}</Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default AuthAfter;
